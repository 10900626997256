import Image from 'next/image'
import Link from 'next/link'
import { ROUTE_ROOT } from '../lib/constants'

export default function Example() {
  return (
    <div className="bg-white min-h-screen flex flex-col lg:relative">
      <div className="flex-grow flex flex-col">
        <main className="flex-grow flex flex-col bg-white">
          <div className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
            <div className="flex-shrink-0 pt-10 sm:pt-16">
              <Link href={ROUTE_ROOT}>
                <a className="inline-flex">
                  <span className="sr-only">Workflow</span>
                  <Image src="/images/workflow-mark-pink-600.svg" height="48" width="48" alt="Logo" />
                </a>
              </Link>
            </div>
            <div className="flex-shrink-0 my-auto py-16 sm:py-32">
              <p className="text-sm font-semibold text-pink-600 uppercase tracking-wide">404 error</p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found</h1>
              <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
              <div className="mt-6">
                <Link href={ROUTE_ROOT}>
                  <a className="text-base font-medium text-pink-600 hover:text-pink-500">
                    Go back home<span aria-hidden="true"> &rarr;</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </main>
        <footer className="flex-shrink-0 bg-gray-50">
          <div className="mx-auto max-w-7xl w-full px-4 py-16 sm:px-6 lg:px-8">
            <nav className="flex space-x-4">
              <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Contact Support
              </a>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Status
              </a>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <a href="#" className="text-sm font-medium text-gray-500 hover:text-gray-600">
                Twitter
              </a>
            </nav>
          </div>
        </footer>
      </div>
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <div className="absolute inset-0 h-full w-full object-cover">
          <Image
            src="/images/split-photos/julentto-photography-CIuakYIjadc-unsplash.jpg"
            alt="navigating via a map laid across a car dashboard"
            height="2862"
            width="1980"
          />
        </div>
      </div>
    </div>
  )
}
